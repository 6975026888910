@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.navbar {
  background-color: #12632d;
  padding-top: 0px;
  height: 60px;
  width: 100%;
  position:absolute;
  top: 0px;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  padding-bottom: 10px;
}

.nav-menu {
  margin-top: 10px;
  background-color: white ;
  width: 250px;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 100;
}

.nav-menu ul{
  padding-left: 0;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  color: #000000;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  transition: 0.45s;
}

.nav-text a:hover {
  color: #000000;
  background-color: #22e36c;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #12632d;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
}

.user-profile__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
}

.user-profile__name {
  color: #000000;
  margin-top: 5px;
}

.icon-text {
  font-family: 'Lato', sans-serif;
  
}

.FaIcon{
  color: #000000;
}

.AiIcons{
  color: #000000;
}